import axios, { AxiosError } from 'axios';

export type CutTypeList = {
	id: string;
	title: string;
	title_en: string;
}[];

/** Апи получения списка типов разделки */
export const getCutTypes = async () => {
	try {
		const { data } = await axios.get<CutTypeList>(`${process.env.HOST}/api_v1/cut_types/`);
		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			console.error(error.response?.data);
		} else {
			console.error(error);
		}
	}
};
